.policyDetailCheckbox.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--blue-500) !important;
  background: var(--blue-500) !important;
}

.policyDetailCheckbox.p-checkbox
  .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--indigo-600) !important;
  background: var(--indigo-600) !important;
}

.policyDetailCheckbox.p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box:hover {
  border-color: var(--blue-500) !important;
}

.policyDetailCheckbox.p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem var(--cyan-200) !important;
  border-color: var(--blue-500) !important;
}

.policyDetailCheckbox.p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: var(--indigo-600) !important;
  background: var(--indigo-600) !important;
}
