#paymentPlanDataTable.p-tabview .p-tabview-panels {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#paymentPlanDataTable.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--green-100) !important;
}

#paymentPlanDataTable.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: #f0fdfa !important;
}

#paymentPlanDataTable.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #ffffff !important;
}
