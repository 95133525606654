.paymentMethodRadioButton.p-radiobutton
  .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--blue-500) !important;
}

.paymentMethodRadioButton.p-radiobutton
  .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem var(--cyan-200) !important;
  border-color: var(--blue-500) !important;
}

.paymentMethodRadioButton.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--blue-500) !important;
  background: var(--blue-500) !important;
}

.paymentMethodRadioButton.p-radiobutton
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--indigo-600) !important;
  background: var(--indigo-600) !important;
}
