#content {
  position: relative;
}

#content .recomended {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(33%, -10%);
}
